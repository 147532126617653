<template>
  <div v-if="dialog && dataConfig">
    <v-dialog :value="dialog" persistent transition="dialog-bottom-transition"  max-width="60%" style="z-index: 9999 !important">
      <v-card>
        <div class="modal-header">
          <div class="row">
            <h5 class="modal-title col-6" id="formModal">
              {{ $t("title.user_limit_betting_usd") }}
            </h5>
          </div>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            @click="$emit('onCloseDialog')"
          >
            <span @click="$emit('onCloseDialog')" aria-hidden="true">×</span>
          </button>
        </div>
        <Loading :visible="loading" />
        <v-card-text>
          <v-container>
            <v-form
              v-if="dataConfig"
              ref="form"
              v-model="valid"
              lazy-validation
            >
            <v-tabs
                background-color="blue-grey lighten-5 accent-4"
                center-active
              >
                    <v-tab v-for="(indexTitle, index) in dataConfig.objectUsd" :key="index">
                      <span v-if=" indexTitle.channel_type == 1">CO1</span>
                      <span v-if=" indexTitle.channel_type == 2">CO2</span>
                      <span v-if=" indexTitle.channel_type == 3">CO3</span>
                      <span v-if=" indexTitle.channel_type == 4">CO4</span>
                      <span v-if=" indexTitle.channel_type == 5">KL1</span>
                      <span v-if=" indexTitle.channel_type == 6">BA1</span>
                      <span v-if=" indexTitle.channel_type == 7">BA2</span>
                      <span v-if=" indexTitle.channel_type == 8">TD1</span>
                      <span v-if=" indexTitle.channel_type == 9">TD2</span>
                      <span v-if=" indexTitle.channel_type == 10">LO1</span>
                      <span v-if=" indexTitle.channel_type == 11">YU1</span>
                      <span v-if=" indexTitle.channel_type == 12">AP1</span>
                      <span v-if=" indexTitle.channel_type == 13">FT1</span>
                      <span v-if=" indexTitle.channel_type == 14">TS1</span>
                    </v-tab>
                  <v-tab-item v-for="(indexO, index1) in dataConfig.objectUsd" :key="index1">
                  <v-card flat>
                    <v-card-text>
                        <v-alert text outlined color="deep-orange"> លុយ ដុល្លា </v-alert>

                        <span class="text"> កំណត់ចំនួនដងនៃការចាក់ </span>
                        <span class="pl-4 limit-text">{{
                          currencyFormatKh(
                            dataConfig.objectParentUsd[indexO.channel_type-1].betting_frequency
                          )
                        }}</span>
                        <vuetify-money
                          class="mt-5"
                          v-bind:options="options"
                          v-model="indexO.betting_frequency"
                          type="number"
                        />

                        <span class="text"> កំណត់ឈ្នះក្នុង១ថ្ងៃធំបំផុត </span>
                        <span class="pl-4 limit-text">{{
                          currencyFormatKh(
                            dataConfig.objectParentUsd[indexO.channel_type-1].total_bet_win_a_day
                          )
                        }}</span>
                        <vuetify-money
                          class="mt-5"
                          v-bind:options="options"
                          v-model="indexO.total_bet_win_a_day"
                          type="number"
                        />

                        <span class="text">កំណត់ភ្នាល់ក្នុង១ដៃធំបំផុត</span>
                        <span class="pl-4 limit-text">{{
                          currencyFormatKh(
                            dataConfig.objectParentUsd[indexO.channel_type-1].total_bet_a_fight
                          )
                        }}</span>
                        <vuetify-money
                          class="mt-5"
                          v-bind:options="options"
                          v-model="indexO.total_bet_a_fight"
                          type="number"
                        />
                        <span class="text">ប្រាក់ភ្នាល់តូចបំផុតក្នុង១ប៉ុង</span>
                        <span class="pl-4 limit-text">
                          {{
                            indexO.currency_type == 1
                              ? 1
                              : indexO.currency_type == 2
                              ? currencyFormatKh(5000)
                              : "loading.."
                          }}</span
                        >
                        <vuetify-money
                          class="mt-5"
                          v-bind:options="options"
                          v-model="indexO.amount_min"
                          type="number"
                        />
                        <span class="text"> ប្រាក់ភ្នាល់ធំបំផុតក្នុង១ប៉ុង</span>
                        <span class="pl-4 limit-text">{{
                          currencyFormatKh(dataConfig.objectParentUsd[indexO.channel_type-1].amount_max)
                        }}</span>
                        <vuetify-money
                          class="mt-5"
                          v-bind:options="options"
                          v-model="indexO.amount_max"
                          type="number"
                        />           
                    </v-card-text>
                  </v-card>
                  <div class="col-12" style="text-align: end;">
                    <v-btn
                      color="primary"
                      :disabled="loading"
                      @click="validate(indexO.channel_type)"
                    >
                    {{ $t("button.save") }}
                    </v-btn>
                  </div>
                  </v-tab-item>
            </v-tabs>
          </v-form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    darkmode: {
      type: Boolean,
    },
    loading: {
      type: Boolean,
    },
    rules: {
      type: Object,
      default: function () {
        return {
          password: [
            (v) => !!v || "Password is required",
            (v) =>
              (!!v && v.length > 5) ||
              "Password must be greater than 5 characters",
          ],
        };
      },
    },
    isValidate: {
      type: Boolean,
      default: true,
    },
    dataConfig: [],
    limitConfig: [],
    // dataConfig: {
    //   type: Object,
    //   default: Object,
    // },
    // limitConfig: {
    //   type: Object,
    //   default: Object,
    // },
  },
  watch: {
    dataConfig: function (newV) {
      this.correctSelectChanel(newV.objectUsd);
      // this.correctSelectChanelKh(newV.objectKh);
    },
  },
  computed: {
    valid: {
      // getter
      get: function () {
        return this.isValidate;
      },
      // setter
      set: function (newValue) {
        this.$emit("update:isValidate", newValue);
      },
    },
  },
  data: () => {
    return {
      showPassword: false,
      options: {
        locale: "integer",
        length: 21,
        precision: 0,
      },
      itemChannelu1: [
        {
          id: 10,
          name: "CO1",
          children: [
            { id: "11", name: "Default", channel_type: 1, vip_option: 1 },
            { id: "12", name: "VIP 1", channel_type: 1, vip_option: 2 },
            { id: "13", name: "VIP 2", channel_type: 1, vip_option: 3 },
          ],
        },
      ],
      itemChannelu2: [
        {
          id: 20,
          name: "CO2",
          children: [
            { id: "21", name: "Default", channel_type: 2, vip_option: 1 },
            { id: "22", name: "VIP 1", channel_type: 2, vip_option: 2 },
            { id: "23", name: " VIP 2", channel_type: 2, vip_option: 3 },
          ],
        },
      ],
      itemChannelu3: [
        {
          id: 30,
          name: "CO3",
          children: [
            { id: "31", name: "Default", channel_type: 3, vip_option: 1 },
            { id: "32", name: "VIP 1", channel_type: 3, vip_option: 2 },
            { id: "33", name: " VIP 2", channel_type: 3, vip_option: 3 },
          ],
        },
      ],
      itemChannelu4: [
        {
          id: 40,
          name: "CO4",
          children: [
            { id: "41", name: "Default", channel_type: 4, vip_option: 1 },
            { id: "42", name: "VIP 1", channel_type: 4, vip_option: 2 },
            { id: "43", name: " VIP 2", channel_type: 4, vip_option: 3 },
          ],
        },
      ],
      itemChannelu5: [
        {
          id: 50,
          name: "KL1",
          children: [
            { id: "51", name: "Default", channel_type: 5, vip_option: 1 },
            { id: "52", name: "VIP 1", channel_type: 5, vip_option: 2 },
            { id: "53", name: " VIP 2", channel_type: 5, vip_option: 3 },
          ],
        },
      ],
      itemChannelu6: [
        {
          id: 60,
          name: "BA1",
          children: [
            { id: "61", name: "Default", channel_type: 6, vip_option: 1 },
            { id: "62", name: "VIP 1", channel_type: 6, vip_option: 2 },
            { id: "63", name: " VIP 2", channel_type: 6, vip_option: 3 },
          ],
        },
      ],
      itemChannelu7: [
        {
          id: 70,
          name: "BA2",
          children: [
            { id: "71", name: "Default", channel_type: 7, vip_option: 1 },
            { id: "72", name: "VIP 1", channel_type: 7, vip_option: 2 },
            { id: "73", name: " VIP 2", channel_type: 7, vip_option: 3 },
          ],
        },
      ],
      itemChannelu8: [
        {
          id: 80,
          name: "TD1",
          children: [
            { id: "81", name: "Default", channel_type: 8, vip_option: 1 },
            { id: "82", name: "VIP 1", channel_type: 8, vip_option: 2 },
            { id: "83", name: " VIP 2", channel_type: 8, vip_option: 3 },
          ],
        },
      ],
      itemChannelu9: [
        {
          id: 90,
          name: "TD2",
          children: [
            { id: "91", name: "Default", channel_type: 9, vip_option: 1 },
            { id: "92", name: "VIP 1", channel_type: 9, vip_option: 2 },
            { id: "93", name: " VIP 2", channel_type: 9, vip_option: 3 },
          ],
        },
      ],
      itemChannelu10: [
        {
          id: 100,
          name: "LO1",
          children: [
            { id: "101", name: "Default", channel_type: 10, vip_option: 1 },
            { id: "102", name: "VIP 1", channel_type: 10, vip_option: 2 },
            { id: "103", name: " VIP 2", channel_type: 10, vip_option: 3 },
          ],
        },
      ],
      itemChannelu11: [
        {
          id: 110,
          name: "YU1",
          children: [
            { id: "111", name: "Default", channel_type: 11, vip_option: 1 },
            { id: "112", name: "VIP 1", channel_type: 11, vip_option: 2 },
            { id: "113", name: " VIP 2", channel_type: 11, vip_option: 3 },
          ],
        },
      ],
      itemChannelu12: [
        {
          id: 120,
          name: "AP1",
          children: [
            { id: "121", name: "Default", channel_type: 12, vip_option: 1 },
            { id: "122", name: "VIP 1", channel_type: 12, vip_option: 2 },
            { id: "123", name: " VIP 2", channel_type: 12, vip_option: 3 },
          ],
        },
      ],
      selectionChannelu1: [],
      selectedChannelu1: [],
      selectionChannelu2: [],
      selectedChannelu2: [],
      selectionChannelu3: [],
      selectedChannelu3: [],
      selectionChannelu4: [],
      selectedChannelu4: [],
      selectionChannelu5: [],
      selectedChannelu5: [],
      selectionChannelu6: [],
      selectedChannelu6: [],
      selectionChannelu7: [],
      selectedChannelu7: [],
      selectionChannelu8: [],
      selectedChannelu8: [],
      selectionChannelu9: [],
      selectedChannelu9: [],
      selectionChannelu10: [],
      selectedChannelu10: [],
      selectionChannelu11: [],
      selectedChannelu11: [],
      selectionChannelu12: [],
      selectedChannelu12: [],
      selectionChannel: [],
    };
  },

  methods: {
    validate(channel_type) {
      if (this.$refs.form.validate()) {
          this.$emit("submit",channel_type);
        // for usd
        this.selectionChannel = [];
        this.selectionChannelu1 = [];
        this.selectedChannelu1 = [];
        this.selectionChannelu2 = [];
        this.selectedChannelu2 = [];
        this.selectionChannelu3 = [];
        this.selectedChannelu3 = [];
        this.selectionChannelu4 = [];
        this.selectedChannelu4 = [];
        this.selectionChannelu5 = [];
        this.selectedChannelu5 = [];
        this.selectionChannelu6 = [];
        this.selectedChannelu6 = [];
        this.selectionChannelu7 = [];
        this.selectedChannelu7 = [];
        this.selectionChannelu8 = [];
        this.selectedChannelu8 = [];
        this.selectionChannelu9 = [];
        this.selectedChannelu9 = [];
        this.selectionChannelu10 = [];
        this.selectedChannelu10 = [];
        this.selectionChannelu11 = [];
        this.selectedChannelu11 = [];
        this.selectionChannelu12 = [];
        this.selectedChannelu12 = [];
       
      }
    },
    currencyFormatKh(num) {
      num = parseFloat(num);
      // return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
      return parseInt(num).toLocaleString("en");
    },
    correctSelectChanel(item) {
      for (let t in item) {
        
        if (
          item[t].channel_type == 1 &&
          item[t].currency_type == 1
        ) {
          for(let i in item[t].channel_allow)
            this.selectionChannelu1.push({
              id:
              item[t].channel_allow[i].channel_type +
                "" +
                item[t].channel_allow[i].vip_option,
              name:
              item[t].channel_allow[i].vip_option == 1
                  ? "Default"
                  : item[t].channel_allow[i].vip_option == 2
                  ? "Vip 1"
                  : item[t].channel_allow[i].vip_option == 3
                  ? "Vip 2"
                  : "",
              vip_option: item[t].channel_allow[i].vip_option,
              channel_type: item[t].channel_allow[i].channel_type,
            });

        }
        if (
          item[t].channel_type == 2 &&
          item[t].currency_type == 1
        ) {
          for(let i in item[t].channel_allow)
          this.selectionChannelu2.push({
            id:
            item[t].channel_allow[i].channel_type +
              "" +
              item[t].channel_allow[i].vip_option,
            name:
            item[t].channel_allow[i].vip_option == 1
                ? "Default"
                : item[t].channel_allow[i].vip_option == 2
                ? "Vip 1"
                : item[t].channel_allow[i].vip_option == 3
                ? "Vip 2"
                : "",
            vip_option: item[t].channel_allow[i].vip_option,
            channel_type: item[t].channel_allow[i].channel_type,
          });
        }
        if (
          item[t].channel_type == 3 &&
          item[t].currency_type == 1
        ) {
          for(let i in item[t].channel_allow)
          this.selectionChannelu3.push({
            id:
            item[t].channel_allow[i].channel_type +
              "" +
              item[t].channel_allow[i].vip_option,
            name:
            item[t].channel_allow[i].vip_option == 1
                ? "Default"
                : item[t].channel_allow[i].vip_option == 2
                ? "Vip 1"
                : item[t].channel_allow[i].vip_option == 3
                ? "Vip 2"
                : "",
            vip_option: item[t].channel_allow[i].vip_option,
            channel_type: item[t].channel_allow[i].channel_type,
          });
        }
        if (
          item[t].channel_type == 4 &&
          item[t].currency_type == 1
        ) {
          for(let i in item[t].channel_allow)
          this.selectionChannelu4.push({
            id:
            item[t].channel_allow[i].channel_type +
              "" +
              item[t].channel_allow[i].vip_option,
            name:
            item[t].channel_allow[i].vip_option == 1
                ? "Default"
                : item[t].channel_allow[i].vip_option == 2
                ? "Vip 1"
                : item[t].channel_allow[i].vip_option == 3
                ? "Vip 2"
                : "",
            vip_option: item[t].channel_allow[i].vip_option,
            channel_type: item[t].channel_allow[i].channel_type,
          });
        }
        if (
          item[t].channel_type == 5 &&
          item[t].currency_type == 1
        ) {
          for(let i in item[t].channel_allow)
          this.selectionChannelu5.push({
            id:
            item[t].channel_allow[i].channel_type +
              "" +
              item[t].channel_allow[i].vip_option,
            name:
            item[t].channel_allow[i].vip_option == 1
                ? "Default"
                : item[t].channel_allow[i].vip_option == 2
                ? "Vip 1"
                : item[t].channel_allow[i].vip_option == 3
                ? "Vip 2"
                : "",
            vip_option: item[t].channel_allow[i].vip_option,
            channel_type: item[t].channel_allow[i].channel_type,
          });
        }
        if (
          item[t].channel_type == 6 &&
          item[t].currency_type == 1
        ) {
          for(let i in item[t].channel_allow)
          this.selectionChannelu6.push({
            id:
            item[t].channel_allow[i].channel_type +
              "" +
              item[t].channel_allow[i].vip_option,
            name:
            item[t].channel_allow[i].vip_option == 1
                ? "Default"
                : item[t].channel_allow[i].vip_option == 2
                ? "Vip 1"
                : item[t].channel_allow[i].vip_option == 3
                ? "Vip 2"
                : "",
            vip_option: item[t].channel_allow[i].vip_option,
            channel_type: item[t].channel_allow[i].channel_type,
          });
        }
        if (
          item[t].channel_type == 7 &&
          item[t].currency_type == 1
        ) {
          for(let i in item[t].channel_allow)
          this.selectionChannelu7.push({
            id:
            item[t].channel_allow[i].channel_type +
              "" +
              item[t].channel_allow[i].vip_option,
            name:
            item[t].channel_allow[i].vip_option == 1
                ? "Default"
                : item[t].channel_allow[i].vip_option == 2
                ? "Vip 1"
                : item[t].channel_allow[i].vip_option == 3
                ? "Vip 2"
                : "",
            vip_option: item[t].channel_allow[i].vip_option,
            channel_type: item[t].channel_allow[i].channel_type,
          });
        }
        if (
          item[t].channel_type == 8 &&
          item[t].currency_type == 1
        ) {
          
          for(let i in item[t].channel_allow)
          this.selectionChannelu8.push({
            id: item[t].channel_allow[i].channel_type + "" + item[t].channel_allow[i].vip_option,
            name: item[t].channel_allow[i].vip_option == 1 ? "Default" : item[t].channel_allow[i].vip_option == 2 ? "Vip 1"
                : item[t].channel_allow[i].vip_option == 3
                ? "Vip 2"
                : "",
            vip_option: item[t].channel_allow[i].vip_option,
            channel_type: item[t].channel_allow[i].channel_type,
          });
        }
        if (
          item[t].channel_type == 9 &&
          item[t].currency_type == 1
        ) {
          for(let i in item[t].channel_allow)
          this.selectionChannelu9.push({
            id:
            item[t].channel_allow[i].channel_type +
              "" +
              item[t].channel_allow[i].vip_option,
            name:
            item[t].channel_allow[i].vip_option == 1
                ? "Default"
                : item[t].channel_allow[i].vip_option == 2
                ? "Vip 1"
                : item[t].channel_allow[i].vip_option == 3
                ? "Vip 2"
                : "",
            vip_option: item[t].channel_allow[i].vip_option,
            channel_type: item[t].channel_allow[i].channel_type,
          });
        }
        if (
          item[t].channel_type == 10 &&
          item[t].currency_type == 1
        ) {
          for(let i in item[t].channel_allow)
          this.selectionChannelu10.push({
            id:
            item[t].channel_allow[i].channel_type +
              "" +
              item[t].channel_allow[i].vip_option,
            name:
            item[t].channel_allow[i].vip_option == 1
                ? "Default"
                : item[t].channel_allow[i].vip_option == 2
                ? "Vip 1"
                : item[t].channel_allow[i].vip_option == 3
                ? "Vip 2"
                : "",
            vip_option: item[t].channel_allow[i].vip_option,
            channel_type: item[t].channel_allow[i].channel_type,
          });
        }
        if (
          item[t].channel_type == 11 &&
          item[t].currency_type == 1
        ) {
          for(let i in item[t].channel_allow)
          this.selectionChannelu11.push({
            id:
            item[t].channel_allow[i].channel_type +
              "" +
              item[t].channel_allow[i].vip_option,
            name:
            item[t].channel_allow[i].vip_option == 1
                ? "Default"
                : item[t].channel_allow[i].vip_option == 2
                ? "Vip 1"
                : item[t].channel_allow[i].vip_option == 3
                ? "Vip 2"
                : "",
            vip_option: item[t].channel_allow[i].vip_option,
            channel_type: item[t].channel_allow[i].channel_type,
          });
        }
        if (
          item[t].channel_type == 12 &&
          item[t].currency_type == 1
        ) {
          for(let i in item[t].channel_allow)
          this.selectionChannelu12.push({
            id:
            item[t].channel_allow[i].channel_type +
              "" +
              item[t].channel_allow[i].vip_option,
            name:
            item[t].channel_allow[i].vip_option == 1
                ? "Default"
                : item[t].channel_allow[i].vip_option == 2
                ? "Vip 1"
                : item[t].channel_allow[i].vip_option == 3
                ? "Vip 2"
                : "",
            vip_option: item[t].channel_allow[i].vip_option,
            channel_type: item[t].channel_allow[i].channel_type,
          });
        }
      }
      this.selectedChannelu1 = this.selectionChannelu1;
      this.selectedChannelu2 = this.selectionChannelu2;
      this.selectedChannelu3 = this.selectionChannelu3;
      this.selectedChannelu4 = this.selectionChannelu4;
      this.selectedChannelu5 = this.selectionChannelu5;
      this.selectedChannelu6 = this.selectionChannelu6;
      this.selectedChannelu7 = this.selectionChannelu7;
      this.selectedChannelu8 = this.selectionChannelu8;
      this.selectedChannelu9 = this.selectionChannelu9;
      this.selectedChannelu10 = this.selectionChannelu10;
      this.selectedChannelu11 = this.selectionChannelu11;
      this.selectedChannelu12 = this.selectionChannelu12;
    },
  },
};
</script>
<style scoped>
.limit-text {
  color: #ef9a9a;
  font-weight: bold;
  font-size: 20px;
}
.text {
  font-weight: bold;
  font-size: 20px;
}
</style>
